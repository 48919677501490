import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JWT_OPTIONS, JwtHelperService, JwtModule  } from '@auth0/angular-jwt';
import { PeAuthService } from './core/services/auth.service';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular'; 
import { environment } from '../environments/environment';
import { SharedModule } from './modules/shared/shared.module';
import { HttpInterceptorService } from './modules/myPrimeRx/services/http-interceptor.service';
import { GlobalErrorsHandlerComponent } from './modules/myPrimeRx/services/global-error-handler';
import { UserLoginComponent } from './modules/myPrimeRx/components/user-login/user-login.component';


@NgModule({
  declarations: [
    AppComponent,
    UserLoginComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AuthModule.forRoot({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
          redirect_uri: environment.authorizationParams.redirect_uri,
          audience: environment.authorizationParams.audience
      },
      cacheLocation: 'localstorage'
    }),
  ],
  providers: [
    PeAuthService, 
    JwtHelperService,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthHttpInterceptor, 
      multi: true 
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { 
      provide: ErrorHandler, 
      useClass: GlobalErrorsHandlerComponent
    },
    HttpInterceptorService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
