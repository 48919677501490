import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, finalize, map, of, switchMap, throwError} from 'rxjs';
import { LoaderService } from '../../../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  private totalRequests = 0;
  constructor(private _loadrServ: LoaderService) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
): Observable<any> {
    const started = Date.now();
    let ok: string
    let  access_token: any = JSON.parse(localStorage.getItem("access_token") as string);
    let showLoader = true;
    req = req.clone({
          headers: req.headers.set(
            "Authorization",
            "Bearer " + access_token
          )});

    const isLoadNotReq = req.headers.get("isLoadNotReq");
    const loaderMsg = req.headers.get("loadingMesssage");
    if (isLoadNotReq !== "true" && showLoader) {
        this.totalRequests++;
        this.startLoader();
    }

    if (loaderMsg) {
        this.showLoaderMsg(loaderMsg);
    }

    return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if(event && event.body && event.body.EventKey){
                 
                } else if (event && event.body && event.body.ErrorMessage) {
                 
                } else if(event && event.body && event.body[0] && event.body[0].ErrorMessage){
                 
                }
                return event;
            }
        }),
        catchError((event: HttpErrorResponse) => {
            if (event instanceof HttpErrorResponse) {
                if (event.error && event.error instanceof Blob) {
                    let reader = new FileReader();
                    reader.onload = (readResult: Event) => {
                        const errmsg = JSON.parse((<any>readResult.target).result);
                      
                    };
                    reader.readAsText(event.error);
                } else if (event.error && event.error.EventKey && event.error.Message) {
           
                } else if (event.error && event.status === 401) {

                } 
            }
            let data = {};
            data = {
                reason:
                event && event.error && event.error.reason ? event.error.reason : "",
                status: event.status,
                url: `${req.method} "${req.urlWithParams}"`
            };
           return throwError(data);
        })
    ).pipe(finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}"
           ${ok} in ${elapsed} ms.`;
        if (isLoadNotReq !== "true") {
            this.decreaseRequests();
        }
        if (loaderMsg) {
            this.removeLoaderMsg();
        }
    }));
}
private startLoader(): void {
  this._loadrServ.display(true);
}

private hideLoader(): void {
  this._loadrServ.display(false);
}

private showLoaderMsg(msg: string): void {
  this._loadrServ.addMessage(msg);
}

private removeLoaderMsg(): void {
  this._loadrServ.addMessage("");
}
private decreaseRequests() {
  this.totalRequests--;
  if (this.totalRequests === 0 || this.totalRequests < 0) {
      this.hideLoader();
  }
}
}
