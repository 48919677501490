import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/myPrimeRx/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { 
    path: '', 
    loadChildren: () => import('./modules/SecurePatientCommunication/components/spc/spc.module').then(m => m.SpcModule) 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
